import React from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined, PhoneOutlined } from '@ant-design/icons';
import './Contact.css';

const { TextArea } = Input;

const ContactForm = () => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    message.success('Form submitted successfully!');
    console.log('Form Values:', values);
  };

  return (
    <div className="contact-form-container">
      <h1 className="contact-form-title">Contact Form</h1>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        className="contact-form"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter your name' }]}
        >
          <Input placeholder="Your name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter a valid email' }
          ]}
        >
          <Input placeholder="Your email" />
        </Form.Item>

        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: 'Please enter your message' }]}
        >
          <TextArea rows={4} placeholder="Your message" />
        </Form.Item>

        <Form.Item
          name="cv"
          label="Upload CV (optional)"
        >
          <Upload
            beforeUpload={() => false}
            listType="text"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>

        <div className="phone-icon-container">
          <a href="tel:+302103465554">
            <PhoneOutlined className="phone-icon" />
          </a>
        </div>
      </Form>
    </div>
  );
};

export default ContactForm;
