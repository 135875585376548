import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { ScissorOutlined, BgColorsOutlined, MedicineBoxOutlined, HeartOutlined, TeamOutlined, PhoneOutlined } from '@ant-design/icons';
import './Services.css';

const Services = () => {
  const [shuffledServices, setShuffledServices] = useState([]);

  const services = [
    {
      key: 1,
      icon: <ScissorOutlined />,
      title: 'Haircuts',
      details: [
        'Wash / Haircut: €17',
        'Wash / Haircut / Blowout: €27',
        'Wash / Haircut / Styling: €30',
        'Blowout: €10',
        'Styling: €13',
        'Men\'s Cut: €13',
        'Children\'s Cut: €10'
      ]
    },
    {
      key: 2,
      icon: <BgColorsOutlined />,
      title: 'Coloring',
      details: [
        'Coloring: €24',
        'Dye: €27',
        'Foil Highlights: €19',
        'Foil Highlights 1/2: €30',
        'Foil Highlights 3/4: €40',
        'Foil Highlights Full: €45'
      ]
    },
    {
      key: 3,
      icon: <MedicineBoxOutlined />,
      title: 'Special Treatments',
      details: [
        'Perm: €45',
        'Straightening: €50',
        'Bridal Package: Upon request.',
        '* Prices for highlights may vary depending on hair length and quantity.'
      ]
    },
    {
      key: 4,
      icon: <HeartOutlined />,
      title: 'Treatments',
      details: ['Treatment: €5']
    },
    {
      key: 5,
      icon: <TeamOutlined />,
      title: 'Human Resources',
      details: [
        'For us, the greatest asset is the people we work with.',
        'We are fortunate to have people with enthusiasm for work, love for the profession, talent, and understanding towards the client.',
        'However, their willingness to continuously educate themselves on new techniques and technologies is most important, which is why our services are constantly evolving.',
        'If you are interested in working at Alexandros Hair Salon, please contact us.'
      ]
    }
  ];

  useEffect(() => {
    setShuffledServices(shuffleArray([...services]));
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <div className="services-container">
      <h1 className="services-title">Services</h1>
      <div className="services-grid">
        {shuffledServices.map(service => (
          <div className="flip-card auto-flip" key={service.key}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Card title={service.title} bordered={false} cover={service.icon} className="service-card">
                  <p>{service.title}</p>
                </Card>
              </div>
              <div className="flip-card-back">
                <Card title={service.title} bordered={false} className="service-card-back">
                  {service.details.map((detail, index) => (
                    <p key={index}>{detail}</p>
                  ))}
                </Card>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="services-note">For more information, call us.</p>
      <div className="phone-icon-container">
        <a href="tel:+302103465554">
          <PhoneOutlined className="phone-icon" />
        </a>
      </div>
    </div>
  );
};

export default Services;
