import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { HomeOutlined, HistoryOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import CustomCarousel from '../components/Carousel';
import './Home.css';

const { Panel } = Collapse;

const Home = () => {
  const images = [
    '/images/portfolio/01.jpg',
    '/images/portfolio/02.jpg',
    '/images/portfolio/03.jpg',
    '/images/portfolio/04.jpg'
  ];

  const [autoFlip] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoFlip) {
        document.querySelectorAll('.flip-card').forEach(card => {
          card.classList.add('auto-flip');
        });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [autoFlip]);

  return (
    <div className="home-container">
      <div className="carousel-container">
        <CustomCarousel images={images} />
      </div>
      <div className="content-container">
        <h1 className="welcome-header">Welcome to Alexandros Hair Salon</h1>
        <Collapse accordion>
          <Panel header={<><HomeOutlined /> Our Profile</>} key="1" className="panel-header">
            <div className="panel-content">
              <p>For 32 years, Alexandros Hair Salon has been serving the Thiseio area with seriousness and consistency.</p>
              <p>With many years of experience in the field of hairdressing, we invite you to enjoy services from the hands of experienced and specialized professionals in a calm and friendly environment, but also in a well-equipped space that can offer you all the comforts.</p>
            </div>
          </Panel>
          <Panel header={<><HistoryOutlined /> Our History</>} key="2" className="panel-header">
            <div className="panel-content">
              <p>The first family salon was founded by Giota Liakopoulou in 1992.</p>
              <p>Since 2001, Alexandros, son of Giota Liakopoulou, after significant studies and extensive practical training in the field, continues to contribute to the salon.</p>
            </div>
          </Panel>
          <Panel header={<><SafetyCertificateOutlined /> Our Policy</>} key="3" className="panel-header">
            <div className="panel-content">
              <p>What sets us apart is the excellent service we offer our clients, resulting in the best possible quality of our services. The most important part of our job is the opportunity to provide our clients with healthy hair combined with a unique style for each one.</p>
              <p>Thus, we have earned the trust of people who have been with us for years and regularly visit our space to entrust us with their needs, insecurities, and desires. We, in turn, offer them our advice and services based on our expertise and love for our profession.</p>
              <p>Having managed to perfectly combine our many years of experience in classic haircuts with the new geometric techniques required for modern haircuts, we are now interested in giving you the style that suits your personality with a result that will express your character and exude confidence.</p>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Home;
