import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import './Carousel.css';

const CustomCarousel = ({ images }) => {
  const [shuffledImages, setShuffledImages] = useState(images);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    let patternToggle = false;

    const moveElements = () => {
      const carouselContainer = document.querySelector('.carousel-container');
      if (!carouselContainer) return;

      const imageElements = document.querySelectorAll('.carousel-image');
      if (!imageElements.length) return;

      const randomContainerX = Math.floor(Math.random() * 40) - 20;
      const randomContainerY = Math.floor(Math.random() * 20) - 10;
      const randomContainerDuration = Math.random() * 2 + 1;
      carouselContainer.style.transition = `transform ${randomContainerDuration}s ease-in-out`;
      carouselContainer.style.transform = `translate(${randomContainerX}px, ${randomContainerY}px)`;

      imageElements.forEach((image) => {
        let randomX, randomY;
        if (patternToggle) {
          randomX = Math.floor(Math.random() * 20) - 10;
          randomY = Math.floor(Math.random() * 10) - 5;
        } else {
          randomX = Math.floor(Math.random() * 30) - 15;
          randomY = Math.floor(Math.random() * 15) - 7.5;
        }
        const randomDuration = Math.random() * 2 + 1;
        image.style.transition = `transform ${randomDuration}s ease-in-out`;
        image.style.transform = `translate(${randomX}px, ${randomY}px)`;
      });

      patternToggle = !patternToggle;
    };

    setShuffledImages(shuffleArray([...images]));

    const shuffleInterval = setInterval(() => {
      setShuffledImages(shuffleArray([...images]));
      moveElements();
    }, 10000);

    return () => clearInterval(shuffleInterval);
  }, [images]);

  return (
    <Carousel autoplay>
      {shuffledImages.map((image, index) => (
        <div key={index} className="carousel-slide">
          <img src={image} alt={`slide-${index}`} className="carousel-image" />
        </div>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
