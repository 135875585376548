import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {
  HomeOutlined,
  AppstoreOutlined,
  MailOutlined,
  PhoneOutlined,
  FacebookOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import './App.css';
import BackgroundAnimation from './components/BackgroundAnimation';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Router>
      <BackgroundAnimation />
      <Layout className="fullscreen-layout">
        <Sider
          theme="dark"
          collapsible
          collapsed={collapsed}
          onCollapse={(collapsed) => setCollapsed(collapsed)}
          breakpoint="lg"
          onBreakpoint={(broken) => {
            if (broken) {
              setCollapsed(true);
            } else {
              setCollapsed(false);
            }
          }}
          className="sidebar-robot"
        >
          <div className="logo">{!collapsed && 'Alexandros Hair Salon'}</div>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" icon={<EnvironmentOutlined />}>
              <a 
                href="https://www.google.com/maps/place/alexandroshairsalon/@37.976933,23.7162736,17z/data=!3m1!4b1!4m6!3m5!1s0x14a1bd200f79f18d:0x3024d28633f32b4!8m2!3d37.976933!4d23.7162736!16s%2Fg%2F11cm0h21cx?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
              >
                Find Us
              </a>
            </Menu.Item>
            <Menu.Item key="2" icon={<PhoneOutlined />}>
              <a href="tel:+302103465554">Call Us</a>
            </Menu.Item>
            <Menu.Item key="3" icon={<HomeOutlined />}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<AppstoreOutlined />}>
              <Link to="/services">Services</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<MailOutlined />}>
              <Link to="/contact">Contact</Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<FacebookOutlined />} title="Social">
              <Menu.Item key="7">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="content-layout">
          <Content className="content-container">
            <div className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </div>
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
